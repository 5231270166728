/*
 * Forms:
 *
 * Form needs styles
 * Form mode
 */


// Form needs styles
// ============================================================================

.form-needs-styles {
  $form-control-ignored-types-list: "checkbox", "radio", "file", "range", "color", "submit", "reset";
  $form-control-ignored-types-not-selector: "";

  @each $type in $form-control-ignored-types-list {
    $form-control-ignored-types-not-selector: "#{$form-control-ignored-types-not-selector}:not([type='#{$type}'])";
  }

  input#{$form-control-ignored-types-not-selector},
  textarea {
    @extend .form-control; // stylelint-disable-line scss/at-extend-no-missing-placeholder
  }

  // Error validation
  .is-invalid {
    input#{$form-control-ignored-types-not-selector},
    textarea {
      @extend .is-invalid; // stylelint-disable-line scss/at-extend-no-missing-placeholder
    }
  }
}


// Form mode
// ============================================================================

.form-mode {
  .show-on-edit-mode {
    display: none;
  }
}

.form-mode.editing {
  .show-on-read-mode {
    display: none;
  }

  .show-on-edit-mode {
    display: block;
  }
}
