/*
 * Viewerjs:
 *
 * Minified css
 */

// Minified css
// ============================================================================

@import "~viewerjs/dist/viewer.min.css";

img.image-viewer {
  width: 100%;
  height: 100%;
  object-fit: contain;
  cursor: pointer;
}
